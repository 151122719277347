import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import WelcomeTitle from '../common/WelcomeTitle';
import TotalLifeHealth from '../common/TotalLifeHealth';
import InformationGroup from '../common/InformationGroup';
import CategoryDrawer from '../clients/CategoryDrawer';
import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import LifeInventoryAssessmentChart from '../common/LifeInventoryAssessmentChart';
import FullscreenLoading from '../common/FullscreenLoading';
import NotesGroup from '../common/NotesGroup';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';
import Moment from "react-moment";
import parse from 'html-react-parser';
import InstructionsCard from '../common/InstructionsCard';
import PDFLink from '../sidebar/PDFLink';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  chart: {
    width: '800px',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  chartLegendContainer: {
    marginTop: theme.spacing(2),
  },
  bubbleExplanation: {
    fontSize: '9px',
  },
  informationContainer: {
    // position: 'relative',
    // top: -theme.spacing(18),
    // zIndex: 50,
    // minWidth: theme.spacing(40),
    // maxWidth: theme.spacing(80),
    // marginLeft: theme.spacing(6),

    // '& > *:not(:first-of-type)': {
    //   marginTop: theme.spacing(4),
    // }
    // gap: '60px'
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
  },
  lifeInventoryAssessmentChart: {
    boxShadow: '0px 1px 12px 0px #00000029',
  },
  surveyCreationDate: {
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
  },
  chartLegendExplanation: {
    marginLeft: "auto",
    svg: {
      display: 'none',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '18px',
      display: 'flex',
      gap: '12px',
      marginLeft: '0',
      fontWeight: '600',
      marginBottom: '24px',
      // justifyContent: 'space-between',
      // width: '100%',

      svg: {
        display: 'block',
      }
    },
  },
  infoContainer: {
    [theme.breakpoints.down('xl')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '32px',
      width: '100%'
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
      maxHeight: '220px'
    },
  },
  summaryContainer_wrapper: {
    [theme.breakpoints.down('xl')]: {
      border: 'none'
    },
  },
  summaryContainer: {
    display: 'none',
    [theme.breakpoints.down('xl')]: {
      marginTop: '48px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      width: '100%',
      paddingTop: '32px',
      borderTop: '1px solid #EAECF0',

      span: {
        fontSize: '15.4px',
        fontWeight: '600',
        textAlign: 'center',
      }
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    paddingBottom: '5px',
  },
  button_share: {
    border: '1px solid #D0D5DD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    backgroundColor: 'transparent',
    width: '100px',
    borderRadius: '8px',
    background: 'white',
  },
  infoButton: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
    zIndex: '2',
    svg: {
      flexShrink: '0'
    }
  },
  tooltip: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '100',
    padding: '56px 16px 32px',
    fontSize: '12px',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px 16px 0 0',
    boxShadow: '0 12px 16px -4px #000',
    transform: 'translateY(100%)',
    transition: '1s',
  },
  tooltip_open: {
    transform: 'translateY(0%)'
  },
  tooltip_close: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
  },
  tooltip_text: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '8px',
  },
  tooltip_title: {
    color: '#0689FB',
    fontSize: '16px',
    lineHeight: '18px',
    paddingBottom: '16px',
    fontWeight: '600',
  },
}));

const ClientAssessment = ((props) => {
  const record = useRecordContext();
  const { assessmentId } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { classes: classes2 } = useStyles();
  const userName = record.firstName;
  const [currentSelector, setCurrentSelector] = useState(null);
  const [currentSelectorType, setCurrentSelectorType] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [assessment, setAssessment] = useState(null);
  const [surveyResults, setSurveyResults] = useState([]);
  const [assessmentMeta, setAssessmentMeta] = useState({});
  const [coachNotes, setCoachNotes] = useState('');
  const [lifeJournal, setLifeJournal] = useState('');
  const isDrawerOpenRef = useRef();
  const redirect = useRedirect();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  isDrawerOpenRef.current = isDrawerOpen;

  useEffect(() => {
    async function fetchData() {
      if (assessmentId) {
        try {
          const { data: response } = await dataProvider.getOne('assessments', { id: assessmentId });

          setSurveyResults(
            enchanceSurveyResults(response.SurveyResults)
          );
          setAssessment(response);
          setCoachNotes(response.summary_notes);
          setLifeJournal(response.client_life_journal);
        } catch (e) {
          redirect('/login');
        }
      }
    }

    setAssessmentMeta(props.record.Surveys.find((survey) => survey.id === parseInt(props.assessmentId)) || {});
    fetchData();
  }, [assessmentId]);

  useEffect(() => {
    setIsHovering(false);
  }, [isDrawerOpen]);

  function handleItemHover(isHovering, selector = null, selectorType = null) {
    if (!isDrawerOpenRef.current && isHovering) {
      setCurrentSelector(selector);
      setCurrentSelectorType(selectorType);
    }

    setIsHovering(isHovering);
  }

  function handleCategoryClick(selector) {
    setCurrentSelector(selector);
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  if (!assessment) return <FullscreenLoading />

  return (
    <>
      <Box className={`${classes2.tooltip} ${isTooltipOpen && classes2.tooltip_open}`}>
        <button className={classes2.tooltip_close} onClick={() => {
          setIsTooltipOpen(false)
        }}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13M1 1L13 13" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <Typography className={classes2.tooltip_title}>Instructions</Typography>
        <Typography className={classes2.tooltip_text}>
          1. Click on items on this page, like the words Flow, Action, Ease, Watch, Fulfillment, and any of the life categories for a detailed description of each.
        </Typography>
        <Typography className={classes2.tooltip_text}>
          2. Click on the description to access your Life Health Dashboards.
        </Typography>
      </Box>
      <Box
        // className={classes.root}
        position="relative"
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <Box
          className={classes2.infoContainer}
        >
          <Box
            className={classes2.info}
          >
            <TotalLifeHealth
              percentage={assessment.total_life_score}
              color={theme.colors.lightTeal}
            />
          </Box>
          <Box>
            <WelcomeTitle
              title={`Welcome back, `}
              userName={userName}
              subtitle={'Life Map is a tool you can use to find focus, align purpose, and live more fully.'}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            className={classes2.chart}
          >
            <Box
              display="flex"
              // className={classes.bubbleExplanation}
              marginTop={6}
            >
              <Box
                className={classes2.surveyCreationDate}
              >
                <Moment format="MMMM, DD YYYY">
                  {assessmentMeta?.created_at}
                </Moment>
              </Box>
              <Box
                className={classes2.chartLegendExplanation}
              >
                Size of bubble = total health of life category
                <button
                  className={classes2.infoButton}
                  onClick={() => {
                    setIsTooltipOpen(true)
                  }}
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#5FB4FE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
              </Box>
            </Box>
            <Box
              marginTop={0.5}
            // className={classes.lifeInventoryAssessmentChart}
            >
              <LifeInventoryAssessmentChart
                surveyResults={surveyResults}
                handleCategoryClick={handleCategoryClick}
                handleItemHover={handleItemHover}
              />
            </Box>
          </Box>
          <Box
            className={classes2.informationContainer}
          >
            <InstructionsCard />
            <Box
              position="absolute"
            >
              <InformationGroup
                selectorType={currentSelectorType}
                selector={currentSelector}
                surveyResults={surveyResults}
                isDrawerOpen={isDrawerOpen}
                isHovering={isHovering}
              />
            </Box>
          </Box>
        </Box>
        <Box
          marginTop={theme.spacing(1)}
          className={classes2.summaryContainer_wrapper}
        >

          <NotesGroup
            assessmentId={assessmentId}
            coachNotes={coachNotes}
            lifeJournal={lifeJournal}
            isDrawerOpen={isDrawerOpen}
            handleCoachNotesUpdate={setCoachNotes}
            handleLifeJournalUpdate={setLifeJournal}
          />
          <div className={classes2.summaryContainer}>
            <span >Summary reflections, general guidance and recommendations won’t show properly on mobile.</span>
            <div className={classes2.buttons}>
              {/* <button className={classes2.button_share}>
                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.65833 11.2583L11.35 14.575M11.3417 5.42499L5.65833 8.74166M16 4.16666C16 5.54737 14.8807 6.66666 13.5 6.66666C12.1193 6.66666 11 5.54737 11 4.16666C11 2.78594 12.1193 1.66666 13.5 1.66666C14.8807 1.66666 16 2.78594 16 4.16666ZM6 9.99999C6 11.3807 4.88071 12.5 3.5 12.5C2.11929 12.5 1 11.3807 1 9.99999C1 8.61928 2.11929 7.49999 3.5 7.49999C4.88071 7.49999 6 8.61928 6 9.99999ZM16 15.8333C16 17.214 14.8807 18.3333 13.5 18.3333C12.1193 18.3333 11 17.214 11 15.8333C11 14.4526 12.1193 13.3333 13.5 13.3333C14.8807 13.3333 16 14.4526 16 15.8333Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                Share
              </button> */}
              <PDFLink />
            </div>
          </div>
        </Box>
        {(() => {
          const index = surveyResults.findIndex((item) => item.selector === currentSelector);
          const item = surveyResults[index] || {};

          return (
            <CategoryDrawer
              userName={userName}
              assessmentId={item.SurveyId}
              category={item.selector}
              categoryName={parse(item.category || '')}
              quadrant={item.quadrant}
              categoryLevel={index + 1}
              categoryCount={surveyResults.length}
              open={isDrawerOpen}
              coachNotes={coachNotes}
              lifeJournal={lifeJournal}
              handleDrawerClose={closeDrawer}
              handleCoachNotesUpdate={setCoachNotes}
              handleLifeJournalUpdate={setLifeJournal}
            />
          )
        })()}
      </Box>
    </>
  );
});

export default ClientAssessment;