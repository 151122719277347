import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import CategoryDrawer from '../clients/CategoryDrawer';
import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import FullscreenLoading from '../common/FullscreenLoading';
import NotesGroup from '../common/NotesGroup';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';
import parse from 'html-react-parser';
import PDFLink from '../sidebar/PDFLink';
import { getCategoryColor } from '../../utils/geCategoryColor';
import { getAssessmentData } from '../../utils/getAssessmentData';
import { getChartData } from '../../utils/getChartData';
import debounce from 'debounce';
import { AssessmentsHeader } from '../common/assessmentComponents/AssessmentsHeader';
import { AssessmentsChartHeader } from '../common/assessmentComponents/AssessmentsChartHeader';
import { AssessmentsChart } from '../common/assessmentComponents/AssessmentsChart';
import { AssessmentsLifeScore } from '../common/assessmentComponents/AssessmentsLifeScore';
import { useAppContext } from '../../contexts/AppContext';
import TotalLifeHealth, { TotalLifeHealthMobile } from '../common/TotalLifeHealth';

export const useStylesAssessments = makeStyles()((theme) => ({
  root: {
  },
  actions_field_button: {
    background: '#003E6F',
    height: '36px',
    textTransform: 'uppercase',
    color: 'white',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',

    '&:hover': {
      background: '#003E6F',
      height: '36px',
      textTransform: 'uppercase',
      color: 'white',
      padding: '6px 16px',

    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  checkProgress: {
    color: 'white',
    background: '#003E6E',
    textTransform: 'uppercase',
    width: '157px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  chart: {
    width: 'calc(100vw - 500px - 128px - 256px - 40px)',
    // [theme.breakpoints.down('xl')]: {
    //   width: '100%',
    // },
    transition: '0.5s',

    // [theme.breakpoints.down('xl')]: {
    //   backgroundColor: 'red !important',
    // },

    [theme.breakpoints.down('xl')]: {
      width: 'calc(100vw - 40px - 20px)',
      margin: '0 auto',
      // width: '100%',
      paddingTop: '0',
      paddingLeft: '0'
    },
  },
  chartNoSidebar: {
    width: 'calc(100vw - 500px - 128px - 40px)',

  },
  chartLegendContainer: {
    marginTop: theme.spacing(2),
  },
  bubbleExplanation: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '13px',
    borderBottom: '1px solid #e0e0e0',

    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
      gap: '12px',
      justifyContent: 'center',
      // display: 'none'
    },
  },
  informationContainer: {
    // position: 'relative',
    // top: -theme.spacing(18),
    // zIndex: 50,
    // minWidth: theme.spacing(40),
    // maxWidth: theme.spacing(80),
    // marginLeft: theme.spacing(6),

    // '& > *:not(:first-of-type)': {
    //   marginTop: theme.spacing(4),
    // }
    // gap: '60px'
    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  lifeInventoryAssessmentChart: {
    // height: '765px'
    padding: '43px',
    // maxHeight: '650px',
    [theme.breakpoints.down('xl')]: {
      padding: '10px',
      paddingTop: '0',
    },
  },
  surveyCreationDate: {
    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  chartLegendExplanation: {
    marginLeft: "auto",
    display: 'flex',
    justifyContent: 'center',
    svg: {
      // display: 'none',
    },
    [theme.breakpoints.down('xl')]: {
      // fontSize: '18px',
      // display: 'flex',
      // gap: '12px',
      // marginLeft: '0',
      // fontWeight: '600',
      // marginBottom: '24px',
      // justifyContent: 'space-between',
      // width: '100%',

      svg: {
        display: 'block',
      }
    },
  },
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 500px',
    marginTop: '32px',
    gap: '32px',
    marginBottom: '32px',

    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr',
      // marginTop: '-80px',
    },
  },
  infoContainerItem: {
    padding: '24px',
    background: 'white',
    borderRadius: '12px',

    [theme.breakpoints.down('xl')]: {
      background: 'transparent',
      padding: '0',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xl')]: {
      // display: 'none'
    },
  },
  life_score: {
    display: 'grid',
    gridTemplateColumns: '1fr 150px',
    gap: '8px',

  },
  life_score_title: {
    marginTop: '31px',
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',

  },
  life_score_text: {
    marginTop: '10px',
    fontSize: '12px',
    color: '#5c5c5c',
    textAlign: 'center',
    marginBottom: '31px',

  },
  summaryContainer_wrapper: {
    // [theme.breakpoints.down('xl')]: {
    //   border: 'none'
    // },
  },
  summaryContainer: {
    display: 'none',
    [theme.breakpoints.down('xl')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      width: '100%',
      paddingTop: '32px',
      borderTop: '1px solid #EAECF0',

      span: {
        fontSize: '15.4px',
        fontWeight: '600',
        textAlign: 'center',
      }
    },
  },
  chartText: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
    color: '#232323',
    margin: "48px 0 0 0",
    [theme.breakpoints.down('xl')]: {
      // marginTop: '0'
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    paddingBottom: '5px',
  },
  button_share: {
    border: '1px solid #D0D5DD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    backgroundColor: 'transparent',
    width: '100px',
    borderRadius: '8px',
    background: 'white',
  },
  select: {
    width: '320px',
    height: '48px',
    padding: '12px',
    // '&::after': {
    //   content: '123'
    // }
    '.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '11px',
      paddingLeft: '0'
    }
  },
  selectLabel: {
    display: 'flex',
    color: '#003E6F',
    alignItems: 'center',
    gap: '8px',
  },
  infoButton: {
    width: '100px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
    zIndex: '2',
    gap: '10px',
    textTransform: 'uppercase',
    color: '#003E6F',
    fontWeight: '600',
    svg: {
      flexShrink: '0'
    },
    [theme.breakpoints.down('xl')]: {
      // width: '24px',
    },
  },
  reportButton: {
    width: '125px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: '1px solid #003E6F',
    gap: '8px',
    textTransform: 'uppercase',
    color: '#003E6F',
    fontWeight: '600',
    borderRadius: '4px',
    textDecoration: 'none !important',
    textDecorationStyle: 'none',
    textDecorationThickness: 'none',
    cursor: 'pointer',
    svg: {
      flexShrink: '0'
    }
  },
  categories: {
    padding: '16px',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '24px'
  },
  categories_title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#003E6F',
  },
  categories_item: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: '25px 1fr 8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    cursor: 'pointer',
    // padding: '16px 0',
  },
  categories_item_percentage_container: {
    flexGrow: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

  },
  categories_item_title: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#000',
  },
  categories_item_percentage: {
    height: '6px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#cccccc',
    position: 'relative',
  },
  categories_item_percentage_fill: {
    position: 'absolute',
    height: '6px',
    top: '0',
    bottom: '0',
    left: '0',
    borderRadius: '4px',
    zIndex: '10'
  },

  tooltip: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '100',
    padding: '56px 16px 32px',
    fontSize: '12px',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px 16px 0 0',
    boxShadow: '0 12px 16px -4px #000',
    transform: 'translateY(100%)',
    transition: '1s',
  },
  tooltip_open: {
    transform: 'translateY(0%)'
  },
  tooltip_close: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
  },
  tooltip_text: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '8px',
  },
  tooltip_title: {
    color: '#0689FB',
    fontSize: '16px',
    lineHeight: '18px',
    paddingBottom: '16px',
    fontWeight: '600',
  },
  notesGroup: {
    [theme.breakpoints.down('xl')]: {
      // display: 'none',
    },
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
  },
}));

const LiaRecordPage = ((props) => {
  const record = useRecordContext();
  const { assessmentId } = props;
  const theme = useTheme();
  const classes = useStylesAssessments();
  const { classes: classes2 } = useStylesAssessments();
  const userName = record.firstName;
  const [currentSelector, setCurrentSelector] = useState(null);
  const [currentSelectorType, setCurrentSelectorType] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [assessment, setAssessment] = useState(null);
  const [surveyResults, setSurveyResults] = useState([]);

  const [coachNotes, setCoachNotes] = useState('');
  const [lifeJournal, setLifeJournal] = useState('');
  const isDrawerOpenRef = useRef();
  const userId = record.id;
  const redirect = useRedirect();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const surveys = record.Surveys ? [...record.Surveys].reverse() : [];

  isDrawerOpenRef.current = isDrawerOpen;

  useEffect(() => {
    async function fetchData() {
      if (assessmentId) {
        try {
          await getChartData({
            assessmentId,
            dataProvider,
            setSurveyResults,
            enchanceSurveyResults,
            setAssessment,
            setCoachNotes,
            setLifeJournal,
          })
        } catch (e) {
          redirect('/login');
        }
      }
    }

    fetchData();
  }, [assessmentId]);

  useEffect(() => {
    setIsHovering(false);
  }, [isDrawerOpen]);

  function handleItemHover(isHovering, selector = null, selectorType = null) {
    if (!isDrawerOpenRef.current && isHovering) {
      setCurrentSelector(selector);
      setCurrentSelectorType(selectorType);
    }

    setIsHovering(isHovering);
  }

  function handleCategoryClick(selector) {
    setCurrentSelector(selector);
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }
  const surveyId = window.location.pathname.split('/')[4]
  const currentSurveys = surveys.find(item => `${item.id}` === surveyId)
  const [age, setAge] = React.useState(currentSurveys?.id || '');
  const handleChange = (event) => {
    setAge(event.target.value);
    redirect(`/client/${userId}/assessments/${event.target.value}`)
  };
  const currentRef = useRef(null);
  const [lists, setLists] = useState([]);

  const [actionList, setActionList] = useState([]);
  const [flowList, setFlowList] = useState([]);
  const [easeList, setEaseList] = useState([]);
  const [watchList, setWatchList] = useState([]);

  const [zoneStatusArray, setZoneStatusArray] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [isDataFetched, setDataFetched] = React.useState(false);
  const [percentage, setPercentage] = useState(0);

  const firstName = record.firstName;
  const resultsDate = surveys.find(item => `${item.id}` === assessmentId)?.created_at || new Date();
  const [onRefetchState, setOnRefetchState] = useState(0);
  const refetch = () => {
    setOnRefetchState(prev => prev + 1)

  }
  useEffect(() => {
    if (currentRef.current === true) return;

    currentRef.current = true
    setDataFetched(false)
    getAssessmentData({
      assessmentId,
      dataProvider,
      setRatingData,
      setZoneStatusArray,
      setPercentage,
      setLists,
      enchanceSurveyResults,
      setDataFetched: () => { },
      setActionList,
      setFlowList,
      setWatchList,
      setEaseList,
    }).finally(() => { currentRef.current = null; setDataFetched(true) })


  }, [assessmentId, onRefetchState]);

  const [isButtonClicked, setButtonClicked] = useState(false);

  const categoriesData = useMemo(() => lists.map(listItem => {
    const percentage = ratingData.find(rate => listItem.selector === rate.selector)?.percentage || 0
    return {
      percentage,
      ...listItem
    }
  }), [lists, ratingData]);
  const { isOpenSidebar } = useAppContext();
  if (!assessment) return <FullscreenLoading />

  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >

        <AssessmentsHeader
          title={`Welcome `}
          userName={`${userName},`}
          subtitle={'A tool you can use to find focus, align purpose and live more fully.'}
        />
        {/* <TotalLifeHealthMobile
          percentage={assessment.total_life_score}
          color={theme.colors.lightTeal}
        /> */}

        <Box
          className={classes2.infoContainer}
        >
          <Box
            className={`${classes2.chart} ${!isOpenSidebar && classes2.chartNoSidebar} ${classes2.infoContainerItem}`}
          >
            <AssessmentsChartHeader
              assessmentId={assessmentId}
              surveys={surveys}
              handleChange={handleChange}
              isDataFetched={isDataFetched}
              // instance={instance}
              firstName={firstName}
              actionList={actionList}
              easeList={easeList}
              flowList={flowList}
              lists={lists}
              percentage={percentage}
              ratingData={ratingData}
              resultsDate={resultsDate}
              theme={theme}
              watchList={watchList}
              zoneStatusArray={zoneStatusArray}
            />
            <AssessmentsChart
              surveyResults={surveyResults}
              handleCategoryClick={handleCategoryClick}
              handleItemHover={handleItemHover}
              setIsTooltipOpen={setIsTooltipOpen}
            />

          </Box>
          <Box
            className={`${classes2.info} ${classes2.infoContainerItem}`}
          >
            <AssessmentsLifeScore
              assessment={assessment}
              isDataFetched={isDataFetched}
              categoriesData={categoriesData}
              handleCategoryClick={handleCategoryClick}
              getCategoryColor={getCategoryColor}
            />

          </Box>
        </Box>
        <Box
          marginTop={theme.spacing(1)}
          className={`${classes2.infoContainerItem} ${classes2.notesGroup}`}
        >


          <NotesGroup
            assessmentId={assessmentId}
            coachNotes={coachNotes}
            lifeJournal={lifeJournal}
            isDrawerOpen={isDrawerOpen}
            handleCoachNotesUpdate={setCoachNotes}
            handleLifeJournalUpdate={setLifeJournal}
            refetch={refetch}
          />


        </Box>
        {(() => {
          const index = surveyResults.findIndex((item) => item.selector === currentSelector);
          const item = surveyResults[index] || {};



          return (
            <CategoryDrawer
              userName={userName}
              assessmentId={item.SurveyId}
              category={item.selector}
              categoryName={parse(item.category || '')}
              quadrant={item.quadrant}
              categoryLevel={index + 1}
              categoryCount={surveyResults.length}
              open={isDrawerOpen}
              coachNotes={coachNotes}
              lifeJournal={lifeJournal}
              handleDrawerClose={closeDrawer}
              handleCoachNotesUpdate={setCoachNotes}
              handleLifeJournalUpdate={setLifeJournal}
              getCategoryColor={getCategoryColor}
            />
          )
        })()}
      </Box >
      <Box className={`${classes2.tooltip} ${isTooltipOpen && classes2.tooltip_open}`}>
        <button className={classes2.tooltip_close} onClick={() => {
          setIsTooltipOpen(false)
        }}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13M1 1L13 13" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <Typography className={classes2.tooltip_title}>Instructions</Typography>
        <Typography className={classes2.tooltip_text}>
          1. Click on items on this page, like the words Flow, Action, Ease, Watch, Fulfillment, and any of the life categories for a detailed description of each.
        </Typography>
        <Typography className={classes2.tooltip_text}>
          2. Click on the description to access your Life Health Dashboards.
        </Typography>
      </Box>
    </>
  );
});

export default LiaRecordPage;