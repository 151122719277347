// in src/ClientLayout.js
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
  Notification, useRecordContext,
} from 'react-admin';
import defaultStyles from '../styles/default';

import { defaultTheme } from 'react-admin';
import ClientSidebar from '../components/sidebar/ClientSidebar';
import IntructionsVideoDialog from '../components/clients/IntructionsVideoDialog';
import ChangeCoachDialog from '../components/clients/changeCoachDialog';
import MobileHeader from './MobileHeader';
import NewSidebar from '../components/sidebar/NewSidebar';
import NewHeader from '../components/sidebar/NewHeader';
import { Content } from './Content';
import { useLocation } from 'react-router-dom';
import { SidebarLink } from '../components/sidebar/SidebarLink';
import { useAppContext } from '../contexts/AppContext';

const theme = createTheme({
  ...defaultTheme.props,
  palette: {
    ...defaultTheme.palette,
    ...defaultStyles.palette,
  },
  colors: {
    ...defaultStyles.colors,
  },
  typography: {
    ...defaultTheme.typography,
    ...defaultStyles.typography,
  },
  overrides: {
    ...defaultTheme.overrides,
    ...defaultStyles.overrides,
  }
});

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#E5EBEA5C',
    position: 'relative',
    // maxWidth: '1366px',
    padding: '0 !important',
    // boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('xl')]: {
      // background: 'white',
    },
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    height: '100vh',
  },
  contentWithSidebar: {
    display: 'grid',
    gridTemplateColumns: '256px 1fr',
    // flexGrow: 1,
    overflow: 'hidden',
    height: '100%',
    transition: '0.5s',

    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr',
    },
  },
  contentWithSidebarHidenSidebar: {
    gridTemplateColumns: '0px 1fr !important',
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr !important',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    ////////////////////
    flexGrow: 2,
    padding: theme.spacing(6),
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      // padding: '20px',
      // overflowX: 'hidden',
      // maxWidth: "100vw",
      // backgroundColor: 'white'
    },
  },
  content_without_padding: {
    padding: '0',

  },
  sidebar: {
    flexShrink: 0,
    zIndex: 5,

    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  player: {
    display: 'block',
  },
}));

const ClientLayout = ({
  children,
  withoutPadding,
  props
}) => {

  const record = useRecordContext()
  const { classes } = useStyles();
  const [playInstructions, setPlayInstructions] = useState(0);
  const [changeCoach, setChangeCoach] = useState(0);


  const handlePlayInstructions = useCallback(() => {
    setPlayInstructions(playInstructions + 1);
  }, [playInstructions]);

  const handleChangeCoach = useCallback(() => {
    setChangeCoach(changeCoach + 1)
  }, [changeCoach]);

  const location = useLocation();
  const currentPage = location.pathname.split('/')[1];


  const sibebarLinks = useMemo(() => [
    {
      to: `/client/${record.id}/assessments/${[...record.Surveys].sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0].id}`,
      isCurrentPage: currentPage === 'client',
      text: 'LIA Record',
      icon: <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path
          d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM6 12H4V10H6V12ZM10 12H8V10H10V12ZM14 12H12V10H14V12ZM6 16H4V14H6V16ZM10 16H8V14H10V16ZM14 16H12V14H14V16Z"
          fill={currentPage === 'client' ? "white" : "#003E6E"}
        />
      </svg>
    },
    {
      text: 'LIA Coach',
      onClick: handleChangeCoach,
      icon: <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 11C8.96667 11 9.79167 10.6583 10.475 9.97499C11.1583 9.29166 11.5 8.46666 11.5 7.49999C11.5 6.53333 11.1583 5.70833 10.475 5.02499C9.79167 4.34166 8.96667 3.99999 8 3.99999C7.03333 3.99999 6.20833 4.34166 5.525 5.02499C4.84167 5.70833 4.5 6.53333 4.5 7.49999C4.5 8.46666 4.84167 9.29166 5.525 9.97499C6.20833 10.6583 7.03333 11 8 11ZM8 17.9C8.98333 17.5833 9.85417 17.0875 10.6125 16.4125C11.3708 15.7375 12.0333 14.975 12.6 14.125C11.8833 13.7583 11.1375 13.4792 10.3625 13.2875C9.5875 13.0958 8.8 13 8 13C7.2 13 6.4125 13.0958 5.6375 13.2875C4.8625 13.4792 4.11667 13.7583 3.4 14.125C3.96667 14.975 4.62917 15.7375 5.3875 16.4125C6.14583 17.0875 7.01667 17.5833 8 17.9ZM8 19.9C7.88333 19.9 7.775 19.8917 7.675 19.875C7.575 19.8583 7.475 19.8333 7.375 19.8C5.125 19.05 3.33333 17.6625 2 15.6375C0.666667 13.6125 0 11.4333 0 9.09999V4.37499C0 3.95833 0.120833 3.58333 0.3625 3.24999C0.604167 2.91666 0.916667 2.67499 1.3 2.52499L7.3 0.274994C7.53333 0.191661 7.76667 0.149994 8 0.149994C8.23333 0.149994 8.46667 0.191661 8.7 0.274994L14.7 2.52499C15.0833 2.67499 15.3958 2.91666 15.6375 3.24999C15.8792 3.58333 16 3.95833 16 4.37499V9.09999C16 11.4333 15.3333 13.6125 14 15.6375C12.6667 17.6625 10.875 19.05 8.625 19.8C8.525 19.8333 8.425 19.8583 8.325 19.875C8.225 19.8917 8.11667 19.9 8 19.9Z"
          fill={false ? "white" : "#003E6E"}
        />
      </svg>
    },

    {
      to: `/compare-progress/${record.id}/assessments`,
      isCurrentPage: currentPage === 'compare-progress',
      text: 'Compare Progress',
      icon: <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H7V22H9V0H7V2ZM7 17H2L7 11V17ZM16 2H11V4H16V17L11 11V20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2Z"
          fill={currentPage === 'compare-progress' ? "white" : "#003E6E"}
        />
      </svg>
    },
    {
      text: 'Video Guide',
      onClick: handlePlayInstructions,
      icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM10 12.5V3.5L16 8L10 12.5Z"
          fill={false ? "white" : "#003E6E"}
        />
      </svg>
    },
  ], [currentPage, handleChangeCoach, handlePlayInstructions, record.Surveys, record.id])

  const { isOpenSidebar } = useAppContext()
  return (
    <ThemeProvider theme={theme}>
      <Container
        className={classes.root}
        maxWidth={false}
      >
        <NewHeader userName={record.userName} />
        {/* <MobileHeader
          handlePlayInstructions={handlePlayInstructions}
          handleChangeCoach={handleChangeCoach}
        /> */}
        <Box className={classes.appFrame}>
          <main className={`${classes.contentWithSidebar} ${!isOpenSidebar && classes.contentWithSidebarHidenSidebar}`}>
            <NewSidebar
              props={{
                height: "100%",
              }}
            >
              {sibebarLinks.map((item) => <SidebarLink key={item.text} {...item} />)}
            </NewSidebar>
            <Content withoutPadding={withoutPadding} record={record}>{children}</Content>
          </main>
          <Notification />
        </Box>
        <IntructionsVideoDialog
          playInstructions={playInstructions}
        />
        <ChangeCoachDialog
          changeCoach={changeCoach}
        />
      </Container>
    </ThemeProvider>
  );
};

export default ClientLayout;