import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Edit,
  useDataProvider,
  Toolbar,
  SaveButton,
  useRecordContext,
  useUpdate,
  useRedirect,
  useNotify
} from "react-admin";

import { roles } from "../../config";

import { makeStyles } from 'tss-react/mui';
import { Box, Breadcrumbs, Button, Grid, Typography, useTheme } from "@mui/material";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more"; //module
import LifeInventoryAssessmentChart from '../common/LifeInventoryAssessmentChart';
import InformationGroup from '../common/InformationGroup';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';
import InstructionsCard from '../common/InstructionsCard';
import CategoryDrawer from '../clients/CategoryDrawer';
import parse from 'html-react-parser';
import { getChartData } from "../../utils/getChartData";
import { getAssessmentData } from "../../utils/getAssessmentData";
import { AssessmentsHeader } from "../common/assessmentComponents/AssessmentsHeader";
import { useStylesAssessments } from "../assessments/LIARecordPage";
import { AssessmentsChartHeader } from "../common/assessmentComponents/AssessmentsChartHeader";
import { AssessmentsChart } from "../common/assessmentComponents/AssessmentsChart";
import { AssessmentsLifeScore } from "../common/assessmentComponents/AssessmentsLifeScore";
import { getCategoryColor } from "../../utils/geCategoryColor";
import { Link } from "react-router-dom";
import NotesGroup from "../common/NotesGroup";
import { useAppContext } from "../../contexts/AppContext";
import { ItemLoader } from "../ui/ItemLoader";


HC_more(Highcharts); //init module

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    background: '#F6F8F7',
    // height: '1000px',
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  drawerPaper: {
    zIndex: 100
  },
  lifeInventoryAssessmentChart: {
    boxShadow: '0px 1px 12px 0px #00000029',
  },
  informationContainer: {
    '& > *:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    }
  },
  edit_page: {
    background: 'transparent',
    '.MuiToolbar-dense': {
      display: 'none',
    },
    '.RaEdit-card': {
      boxShadow: 'none',
    }
  }
}));

const PostSaveButton = props => {
  const notify = useNotify();
  const record = useRecordContext()
  const [update] = useUpdate();
  const redirect = useRedirect();
  const onSuccess = (response) => {
    // notify(`Post "${response.data.title}" saved!`);
    // redirect('/posts');
  };

  const handleClick = e => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = record;
    update(
      'clients',
      { id, data },
      {
        onSuccess: () => {
          notify(`Client information saved!`, { type: "success", undoable: "false" });
        }
      }
    );
  };
  return <SaveButton  {...props}
    type="button"
    alwaysEnable
    mutationOptions={{ onSuccess: onSuccess }}
    transform={data => ({
      ...data,
      coach_id: record.coach_id
    })}
    onClick={handleClick}
  />;
};

// const redirect = (basePath, id, data) => `/clients/${data.client_id}/`;
const PostEditToolbar = props => {
  const record = useRecordContext()// props.record

  const [update] = useUpdate();
  const redirect = useRedirect();


  return (
    <Toolbar {...props} >
      <PostSaveButton />
      {/* <SaveButton alwaysEnable
        
        mutationOptions={{ onSuccess: handleSuccess }}
      /> */}
    </Toolbar>
  );
};

export const IconBreadcrumbs = ({ name, lastItem }) => {
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <div style={{ marginBottom: '32px' }} role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" separator={
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.08748 5L6.91248 6.175L10.7291 10L6.91248 13.825L8.08748 15L13.0875 10L8.08748 5Z" fill="#667085" />
        </svg>
      }>
        <Link
          sx={{ display: 'flex', alignItems: 'center' }}
          color="#5C5C5C"
          to="/clients"
          style={{ textDecoration: 'none' }}
        >
          <svg style={{ marginRight: '9px' }} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33329 14.6667V9.66667H10.6666V14.6667H14.8333V8H17.3333L8.99996 0.5L0.666626 8H3.16663V14.6667H7.33329Z" fill="#667085" />
          </svg>

          Clients
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="#5C5C5C"

        >
          <svg style={{ marginRight: '9px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00004 0.333008C7.8841 0.333008 8.73194 0.684197 9.35706 1.30932C9.98218 1.93444 10.3334 2.78229 10.3334 3.66634C10.3334 4.5504 9.98218 5.39824 9.35706 6.02336C8.73194 6.64849 7.8841 6.99967 7.00004 6.99967C6.11599 6.99967 5.26814 6.64849 4.64302 6.02336C4.0179 5.39824 3.66671 4.5504 3.66671 3.66634C3.66671 2.78229 4.0179 1.93444 4.64302 1.30932C5.26814 0.684197 6.11599 0.333008 7.00004 0.333008ZM7.00004 8.66634C10.6834 8.66634 13.6667 10.158 13.6667 11.9997V13.6663H0.333374V11.9997C0.333374 10.158 3.31671 8.66634 7.00004 8.66634Z" fill="#667085" />
          </svg>

          {name}
        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="#003E6F"

        >

          <svg style={{ marginRight: '9px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4999 14.1663V6.66634H5.83325V14.1663H17.4999ZM17.4999 2.49967C17.9419 2.49967 18.3659 2.67527 18.6784 2.98783C18.991 3.30039 19.1666 3.72431 19.1666 4.16634V14.1663C19.1666 14.6084 18.991 15.0323 18.6784 15.3449C18.3659 15.6574 17.9419 15.833 17.4999 15.833H5.83325C5.39122 15.833 4.9673 15.6574 4.65474 15.3449C4.34218 15.0323 4.16659 14.6084 4.16659 14.1663V4.16634C4.16659 3.72431 4.34218 3.30039 4.65474 2.98783C4.9673 2.67527 5.39122 2.49967 5.83325 2.49967H6.66659V0.833008H8.33325V2.49967H14.9999V0.833008H16.6666V2.49967H17.4999ZM2.49992 17.4997H14.1666V19.1663H2.49992C2.05789 19.1663 1.63397 18.9907 1.32141 18.6782C1.00885 18.3656 0.833252 17.9417 0.833252 17.4997V7.49967H2.49992V17.4997ZM15.8333 12.4997H12.4999V9.16634H15.8333V12.4997Z" fill="#003E6F" />
          </svg>


          {lastItem || 'Assessments'}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}

export const ClientEdit = props => {
  // const toolbar = window.location.pathname.includes('assessments')
  //   ? false
  //   : <PostEditToolbar />;

  const toolbar = <PostEditToolbar />;

  const { classes } = useStyles()

  return (
    <Edit className={classes.edit_page}>
      {/* <TabbedForm toolbar={toolbar}>
        <TabbedForm.Tab label="Summary" noValidate>
          <ClientForm />
        </TabbedForm.Tab >
        <FormTab label="Assessments" noValidate>
        <FormTab label="Assessments" noValidate>
        </FormTab>
      </TabbedForm> */}
      <ClientAssessments />

    </Edit>
  );
};

const ClientAssessments = props => {
  const theme = useTheme();
  const { classes } = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [surveyResults, setSurveyResults] = useState([]);
  const { classes: classes2 } = useStylesAssessments();
  const [assessment, setAssessment] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [currentSelector, setCurrentSelector] = useState(null);
  const [currentSelectorType, setCurrentSelectorType] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [coachNotes, setCoachNotes] = useState('');
  const [lifeJournal, setLifeJournal] = useState('');
  const isDrawerOpenRef = useRef();
  const record = useRecordContext();
  const surveys = record.Surveys.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at)) || [];

  const [assessmentId, setAssessmentId] = useState(record.Surveys[0].id);
  const handleChange = (event) => {
    setAssessmentId(event.target.value);
  };
  const userName = record.firstName;
  function handleItemHover(isHovering, selector = null, selectorType = null) {
    if (!isDrawerOpenRef.current && isHovering) {
      setCurrentSelector(selector);
      setCurrentSelectorType(selectorType);
    }

    setIsHovering(isHovering);
  }

  function handleCategoryClick(selector) {
    setCurrentSelector(selector);
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }
  const [isDataFetched, setDataFetched] = React.useState(false);
  const currentRef = useRef(null);
  const [lists, setLists] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [flowList, setFlowList] = useState([]);
  const [easeList, setEaseList] = useState([]);
  const [watchList, setWatchList] = useState([]);
  const [zoneStatusArray, setZoneStatusArray] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const resultsDate = surveys.find(item => `${item.id}` === assessmentId)?.created_at || new Date();
  // const [instance, update] = usePDF({ document: <Document id={assessmentId} /> });
  const [onRefetchState, setOnRefetchState] = useState(0);
  const refetch = () => {
    setOnRefetchState(prev => prev + 1)

  }
  useEffect(() => {
    async function fetchData() {

      if (assessmentId) {
        try {
          await getChartData({
            assessmentId,
            dataProvider,
            setSurveyResults,
            enchanceSurveyResults,
            setAssessment,
            setCoachNotes,
            setLifeJournal,
          })

        } catch (e) {
          redirect('/login');
        }
        setShowDetail(true);
      }
    }
    fetchData();

    if (currentRef.current === true) return;

    currentRef.current = true
    setDataFetched(false)
    getAssessmentData({
      assessmentId,
      dataProvider,
      setRatingData,
      setZoneStatusArray,
      setPercentage,
      setLists,
      enchanceSurveyResults,
      setDataFetched: () => { },
      setActionList,
      setFlowList,
      setWatchList,
      setEaseList,
    }).finally(() => {
      currentRef.current = null; setDataFetched(true)
    })
  }, [assessmentId, onRefetchState]);

  const categoriesData = useMemo(() => lists.map(listItem => {
    const percentage = ratingData.find(rate => listItem.selector === rate.selector)?.percentage || 0
    return {
      percentage,
      ...listItem
    }
  }), [lists, ratingData]);
  const { isOpenSidebar } = useAppContext();

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className={classes.root}
    >

      <IconBreadcrumbs name={userName} />
      <AssessmentsHeader
        whithCompareButton
        whithButtonBack
        title={''}
        userName={`${userName}`}
        subtitle={'A tool you can use to find focus, align purpose and live more fully.'}
      />




      <Box
        className={classes2.infoContainer}
      >
        <Box className={`${classes2.chart} ${!isOpenSidebar && classes2.chartNoSidebar} ${classes2.infoContainerItem}`}
        >
          <AssessmentsChartHeader
            assessmentId={assessmentId}
            surveys={surveys}
            handleChange={handleChange}
            isDataFetched={isDataFetched}
            firstName={userName}
            actionList={actionList}
            easeList={easeList}
            flowList={flowList}
            lists={lists}
            percentage={percentage}
            ratingData={ratingData}
            resultsDate={resultsDate}
            theme={theme}
            watchList={watchList}
            zoneStatusArray={zoneStatusArray}
          />
          <AssessmentsChart
            surveyResults={surveyResults}
            handleCategoryClick={handleCategoryClick}
            handleItemHover={handleItemHover}
          />

        </Box>
        <Box
          className={`${classes2.info} ${classes2.infoContainerItem}`}
        >
          <AssessmentsLifeScore
            assessment={assessment}
            isDataFetched={isDataFetched}
            categoriesData={categoriesData}
            handleCategoryClick={handleCategoryClick}
            getCategoryColor={getCategoryColor}
          />

        </Box>
      </Box>
      <Box
        marginTop={theme.spacing(1)}
        className={classes2.infoContainerItem}
      >
        {isDataFetched ? <NotesGroup
          assessmentId={assessmentId}
          coachNotes={coachNotes}
          lifeJournal={lifeJournal}
          isDrawerOpen={isDrawerOpen}
          handleCoachNotesUpdate={setCoachNotes}
          handleLifeJournalUpdate={setLifeJournal}
          refetch={refetch}
        /> : <ItemLoader />}

        {/* <div className={classes2.summaryContainer}>
          <span >Summary reflections, general guidance and recommendations here.</span>
          <div className={classes2.buttons}>
          </div>
        </div> */}
      </Box>
      {(() => {
        const index = surveyResults.findIndex((item) => item.selector === currentSelector);
        const item = surveyResults[index] || {};

        return (
          <CategoryDrawer
            userName={userName}
            assessmentId={item.SurveyId}
            category={item.selector}
            categoryName={parse(item.category || '')}
            quadrant={item.quadrant}
            categoryLevel={index + 1}
            categoryCount={surveyResults.length}
            open={isDrawerOpen}
            coachNotes={coachNotes}
            lifeJournal={lifeJournal}
            handleDrawerClose={closeDrawer}
            handleCoachNotesUpdate={setCoachNotes}
            handleLifeJournalUpdate={setLifeJournal}
            getCategoryColor={getCategoryColor}
          />
        )
      })()}
    </Box >
  );
};
