import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import {
  useLogin,
  useNotify,
  Notification,
  TextInput,
  required,
  Form,
  usePermissions,
  PasswordInput,
  useRedirect
} from "react-admin";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { createTheme } from "@mui/material/styles";

import red from "@mui/material/colors/red";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Logo from '../components/common/Logo';

import { Typography, Box } from "@mui/material";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
    minHeight: "100vh",
    position: "relative",
  },
  loginText: {
    textAlign: "center",
  },
  grid: { backgroundColor: "Lavender" }
}));

const myTheme = createTheme({
  palette: {
    primary: {
      main: "#182C3B"
    },
    secondary: {
      main: "#3694F9"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white" // Some CSS
      }
    }
  }
});

const LoginPage = props => {
  const { classes } = useStyles();
  const [email, setEmail] = useState(" 123");
  const [password, setPassword] = useState(" 123123");
  const [role, setRole] = React.useState('1');
  const windowRef = useRef(null)
  const login = useLogin();
  const redirect = useRedirect();
  const notify = useNotify();
  const submit = e => {
    e.preventDefault();
    login({ username: email, password, role })
      .catch(() =>
        notify("Invalid email or password", "warning")
      );
  };
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsDisabled(false)

    }, 600)

    return () => {
      clearTimeout(timeOut)
    }
  }, [])
  // console.log(windowRef.current?.value)

  const handleRole = (event, role) => {
    setRole(role);
  };

  const preventDefault = event => event.preventDefault();
  return (
    <ThemeProvider theme={createTheme(myTheme)}>
      <div className={classes.root}>
        <CssBaseline />

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid

            item xs={9} sm={6} md={3}>
            <Form
              classes={classes.grid} onSubmit={submit}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Logo
                  height={100}
                />
                <Typography variant="h6" className={classes.loginText}>
                  Login
                </Typography>
                <TextInput
                  disabled={isDisabled}
                  inputRef={windowRef}
                  name="email"
                  source="email"
                  type="email"
                  label="E-mail"
                  value={email}
                  onChange={e =>
                    setEmail(e.target.value)
                  }
                  validate={[required()]}
                />
                <PasswordInput
                  disabled={isDisabled}
                  name="password"
                  source="password"
                  value={password}
                  validate={[required()]}
                  onChange={e =>
                    setPassword(e.target.value)
                  }
                />
                Login as
                <ToggleButtonGroup
                  value={role}
                  exclusive
                  onChange={handleRole}
                  aria-label="role"
                >
                  <ToggleButton value="1" aria-label="client">
                    Client
                  </ToggleButton>
                  <ToggleButton value="2" aria-label="coach">
                    Coach
                  </ToggleButton>
                </ToggleButtonGroup>
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Link
                    href="/forgot"
                    color="inherit"
                    variant="body2"
                  >
                    Forgot password
                  </Link>
                </Box>

                <Box mt="1em" />
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                  >
                    SIGN IN
                  </Button>
                </Box>
                {/* </Toolbar> */}
                <Notification />
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider >
  );
};

export default LoginPage;
